<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="align-center light-blue-bg pa-2 flex-wrap">
							<v-flex md12 class="d-flex flex-wrap align-center">
								<h4 class="fw-500 title-text text-capitalize black--text mb-0">
									{{ getValue(seo_detail, "project.sales_person.display_name") }}
								</h4>
								<!-- <v-chip
									class="mr-1 text-capitalize"
									color="cyan white--text"
									x-small
									v-if="this.project && this.project.status == 1"
									>Draft
								</v-chip>
								<v-chip
									class="mr-1 text-capitalize"
									text-color=""
									outlined
									color="orange white--text"
									x-small
									v-if="this.project && this.project.status == 3"
									>renewed
								</v-chip>
								<v-chip
									class="mr-1 text-capitalize"
									color="green white--text"
									x-small
									v-if="this.project && this.project.status == 3"
									>Completed
								</v-chip>
								<v-chip
									class="mr-1 text-capitalize"
									color="red white--text"
									x-small
									v-if="this.project && this.project.status == 4"
									>Cancelled
								</v-chip> -->
								<!-- v-if="customer && customer.company_name" -->
								<p
									class="my-auto custom-title cursor-pointer font-level-3-bold fw-600 text-truncate"
									style="
										max-width: 757px;
										text-decoration-line: underline;
										margin-top: -4px !important;
										font-size: 20px !important;
									"
									@click="clickAndCopy(getValue(seo_detail, 'customer_urls.0.website_urls'))"
								>
									<!-- {{ customer.company_name }} :-
											{{ customer.barcode }} -->
									{{ getValue(seo_detail, "customer_urls.0.website_urls") }}
								</p>

								<!-- <v-btn depressed tile color="blue darken-4" class="text-white ml-3 rounded" :ripple="false">
									Status (Active, Hold, Closed)
									{{ status[seo_detail.status] }}
								</v-btn> -->

								<!-- <div class="seo-detail-status ml-3">
									<v-select
										class="listing-select pr-0"
										hide-details
										:disabled="
											pageLoading ||
											getValue(seo_detail, 'request_status') > 0 ||
											(userType && userType != 'seo')
										"
										:items="statusList"
										v-model="status"
										solo
										item-text="title"
										item-value="value"
										style="
											max-width: 125px !important;
											min-width: 125px !important;
											max-height: 25px !important;
											background-color: transparent;
										"
										:menu-props="{ offsetY: true, bottom: true }"
										@change="doAction"
									>
									
									</v-select>
								</div> -->

								<div class="d-flex justify-space-between align-center ms-3">
									<v-menu
										:disabled="
											pageLoading ||
											getValue(seo_detail, 'request_status') > 0 ||
											(userType == 'seo' &&
												!AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin', 'team_lead']))
										"
										offset-y
										style="padding: 0px 4px"
										rounded
										dense
										content-class="rounded-lg"
									>
										<template #activator="{ on, attrs }">
											<div
												v-bind="attrs"
												v-on="on"
												class="d-flex align-center"
												:class="{
													'justify-space-between':
														getValue(seo_detail, 'request_status') <= 0 &&
														((userType == 'seo' &&
															AllowAnyOforGetRoleNType([
																'master',
																'admin',
																'manager',
																'super_admin',
																'team_lead',
															])) ||
															userType == 'sales' ||
															userType == 'accounts'),
													'justify-center':
														getValue(seo_detail, 'request_status') > 0 &&
														((userType == 'seo' &&
															!AllowAnyOforGetRoleNType([
																'master',
																'admin',
																'manager',
																'super_admin',
																'team_lead',
															])) ||
															userType == 'sales' ||
															userType == 'accounts'),
												}"
												style="
													border: 2px solid;
													padding: 1px 6px;
													background-color: #f7faff;
													border-radius: 5px;
												"
												:style="{
													width:
														getValue(seo_detail, 'request_status') <= 0 &&
														((userType == 'seo' &&
															AllowAnyOforGetRoleNType([
																'master',
																'admin',
																'manager',
																'super_admin',
																'team_lead',
															])) ||
															userType == 'sales' ||
															userType == 'accounts')
															? '90px'
															: '67px',
													borderColor: `${currentStatus?.color} !important`,
												}"
											>
												<span
													class="text-capitalize fw-600 font-level-2 text-uppercase"
													:style="{ color: currentStatus?.color }"
													>{{ currentStatus?.title }}</span
												>
												<v-icon
													v-if="
														getValue(seo_detail, 'request_status') <= 0 &&
														((userType == 'seo' &&
															AllowAnyOforGetRoleNType([
																'master',
																'admin',
																'manager',
																'super_admin',
																'team_lead',
															])) ||
															userType == 'sales' ||
															userType == 'accounts')
													"
													style="font-size: 22px"
													:color="currentStatus?.color"
													small
												>
													{{ attrs && attrs["aria-expanded"] == "false" ? "mdi-menu-down" : "mdi-menu-up" }}
												</v-icon>
											</div>
										</template>

										<v-list elevation dense width="100px" class="py-0 px-0">
											<v-list-item
												class="list-item d-flex align-center cursor-pointer p-1"
												v-for="(item, index) in statusToList"
												:key="index"
												@change="doAction(item.value)"
											>
												<v-list-item-icon class="ma-0">
													<v-icon size="20" left :color="`${item.color} darken-3`">{{ item.icon }}</v-icon>
												</v-list-item-icon>
												<v-list-item-title>{{ item.title }}</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>

								<v-tooltip
									right
									v-if="
										(getValue(seo_detail, 'request_status') ||
											+getValue(seo_detail, 'request_status') != 0) &&
										approval_count <= 3 &&
										(AllowAnyOforGetRoleNType([
											'master',
											'admin',
											'manager',
											'super_admin',
											'team_lead',
											'ateam_lead',
										]) ||
											getValue(Login_User, 'users_type') == 'sales')
									"
								>
									<template #activator="{ on, attrs }">
										<v-btn
											v-on="on"
											v-bind="attrs"
											depressed
											tile
											color="blue darken-4"
											:max-width="40"
											:min-width="30"
											class="text-white ml-3 rounded"
											:ripple="false"
										>
											{{ approval_count }}/3
										</v-btn>
									</template>

									<div style="min-width: 100%">
										<p style="font-size: 13px">Approval count - {{ approval_count }}</p>
										<p v-if="pending_approval().length > 0" style="font-size: 13px">Pending Approvals</p>
										<v-divider v-if="pending_approval().length > 0" class="my-1"></v-divider>
										<div style="font-size: 12px; min-width: 100%" class="d-flex justify-content between">
											<p>Sales Owner&nbsp; -</p>
											<p>
												&nbsp; ({{
													getValue(seo_detail, "sales_owner_approve") == 0
														? "Pending"
														: getValue(seo_detail, "sales_owner_approve") == 1
														? "Approved"
														: "Rejected"
												}})
											</p>
										</div>
										<div style="font-size: 12px; min-width: 100%" class="d-flex justify-content between">
											<p>Team Lead&nbsp;&nbsp;&nbsp; -</p>
											<p>
												&nbsp; ({{
													getValue(seo_detail, "team_lead_approve") == 0
														? "Pending"
														: getValue(seo_detail, "team_lead_approve") == 1
														? "Approved"
														: "Rejected"
												}})
											</p>
										</div>
										<div style="font-size: 12px; min-width: 100%" class="d-flex justify-content between">
											<p>Account&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -</p>
											<p>
												&nbsp; ({{
													getValue(seo_detail, "account_approve") == 0
														? "Pending"
														: getValue(seo_detail, "account_approve") == 1
														? "Approved"
														: "Rejected"
												}})
											</p>
										</div>
									</div>
								</v-tooltip>

								<!-- <v-btn depressed tile color="blue darken-4" class="text-white ml-3 rounded" :ripple="false">
									KPI: Promised / Current (Top 5: 4/3, Top 10: 10/10)
								</v-btn> -->
								<!-- {{ getProfile?.id }}
								{{ seoTeams?.team_lead }} -->
								<!-- {{ currentUser?.id }}
								{{ seoTeams?.sales_owner }}
								{{  seo_detail?.sales_owner_approve }} -->
								<!-- {{ seoTeams }} -->

								<!-- v-if="
										getValue(seo_detail, 'request_status') &&
										getValue(seo_detail, 'request_status') > 0 &&
										((getValue(currentUser, 'id') == getValue(seoTeams, 'sales_owner') &&
											getValue(seo_detail, 'sales_owner_approve') == 0) ||
											(getValue(currentUser, 'id') == getValue(seoTeams, 'team_lead') &&
												getValue(seo_detail, 'team_lead_approve') == 0) ||
											(getValue(getProfile, 'users_type') == 'accounts' &&
												getValue(seo_detail, 'account_approve') == 0))
									" -->

								<template
									v-if="
										getValue(seo_detail, 'request_status') &&
										getValue(seo_detail, 'request_status') > 0 &&
										((getValue(currentUser, 'id') == getValue(seoTeams, 'sales_owner') &&
											getValue(seo_detail, 'sales_owner_approve') == 0) ||
											(getValue(currentUser, 'id') == getValue(seoTeams, 'team_lead') &&
												getValue(seo_detail, 'team_lead_approve') == 0) ||
											(getValue(getProfile, 'users_type') == 'accounts' &&
												getValue(seo_detail, 'account_approve') == 0))
									"
								>
									<v-btn
										depressed
										tile
										color="green darken-4"
										class="text-white ml-3 rounded"
										:ripple="false"
										@click="doAction(4)"
									>
										<v-icon left size="16" class="mr-1">mdi-check</v-icon>
										Approve
									</v-btn>
									<v-btn
										depressed
										tile
										@click="doAction(5)"
										color="red darken-4"
										class="text-white ml-3 rounded"
										:ripple="false"
									>
										<v-icon left size="16" class="mr-1">mdi-close</v-icon>
										Reject
									</v-btn>
								</template>

								<!-- <v-btn depressed tile color="blue darken-4" class="text-white ml-3 rounded" :ripple="false">
									Sales: 10
								</v-btn> -->

								<v-spacer></v-spacer>
								<v-btn depressed tile class="my-auto" @click="backToListing">
									<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
									back
								</v-btn>
								<FilterTask v-if="seoTab == 'taskview'"></FilterTask>
							</v-flex>

							<v-layout class="my-2 rounded elevation-3 align-center dark-blue-bg px-5">
								<v-row class="" align="center" no-gutters>
									<v-col md="12" cols="8" class="px-0 py-1">
										<div class="h5 mb-0 fw-600">Promised KPI</div>
									</v-col>
									<v-col md="12" sm="12" lg="6" cols="11" class="px-0">
										<div class="d-flex align-center">
											<p class="fw-500" style="min-width: 84px">To Customer :</p>
											<div class="ml-3">
												<v-chip
													v-if="getValue(seo_detail, 'to_customer.top_3')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 3 :
														{{
															getValue(seo_detail, "current_rank.top_3")
																? getValue(seo_detail, "current_rank.top_3")
																: "-"
														}}/{{
															getValue(seo_detail, "to_customer.top_3")
																? getValue(seo_detail, "to_customer.top_3")
																: "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_customer.top_5')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 5 :
														{{
															getValue(seo_detail, "current_rank.top_5")
																? getValue(seo_detail, "current_rank.top_5")
																: "-"
														}}/{{
															getValue(seo_detail, "to_customer.top_5")
																? getValue(seo_detail, "to_customer.top_5")
																: "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_customer.top_10')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 10 :
														{{
															getValue(seo_detail, "current_rank.top_10")
																? getValue(seo_detail, "current_rank.top_10")
																: "-"
														}}/{{
															getValue(seo_detail, "to_customer.top_10")
																? getValue(seo_detail, "to_customer.top_10")
																: "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_customer.top_20')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 20 :
														{{
															getValue(seo_detail, "current_rank.top_20")
																? getValue(seo_detail, "current_rank.top_20")
																: "-"
														}}/{{
															getValue(seo_detail, "to_customer.top_20")
																? getValue(seo_detail, "to_customer.top_20")
																: "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_customer.top_50')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 50 :
														{{
															getValue(seo_detail, "current_rank.top_50")
																? getValue(seo_detail, "current_rank.top_50")
																: "-"
														}}/{{
															getValue(seo_detail, "to_customer.top_50")
																? getValue(seo_detail, "to_customer.top_50")
																: "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_customer.top_100')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 100 :
														{{
															getValue(seo_detail, "current_rank.top_100")
																? getValue(seo_detail, "current_rank.top_100")
																: "-"
														}}/{{
															getValue(seo_detail, "to_customer.top_100")
																? getValue(seo_detail, "to_customer.top_100")
																: "-"
														}}</span
													>
												</v-chip>
											</div>
										</div>
									</v-col>

									<v-col md="12" sm="12" lg="6" cols="11" class="px-0">
										<div class="d-flex align-center pl-1 pb-1">
											<p class="fw-500" style="min-width: 84px">To Delhi :</p>
											<div class="ml-3">
												<v-chip
													v-if="getValue(seo_detail, 'to_delhi.top_3')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 3 :
														{{
															getValue(seo_detail, "current_rank.top_3")
																? getValue(seo_detail, "current_rank.top_3")
																: "-"
														}}/{{
															getValue(seo_detail, "to_delhi.top_3") ? getValue(seo_detail, "to_delhi.top_3") : "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_delhi.top_5')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 5 :
														{{
															getValue(seo_detail, "current_rank.top_5")
																? getValue(seo_detail, "current_rank.top_5")
																: "-"
														}}/{{
															getValue(seo_detail, "to_delhi.top_5") ? getValue(seo_detail, "to_delhi.top_5") : "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_delhi.top_10')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 10 :
														{{
															getValue(seo_detail, "current_rank.top_10")
																? getValue(seo_detail, "current_rank.top_10")
																: "-"
														}}/{{
															getValue(seo_detail, "to_delhi.top_10")
																? getValue(seo_detail, "to_delhi.top_10")
																: "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_delhi.top_20')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 20 :
														{{
															getValue(seo_detail, "current_rank.top_20")
																? getValue(seo_detail, "current_rank.top_20")
																: "-"
														}}/{{
															getValue(seo_detail, "to_delhi.top_20")
																? getValue(seo_detail, "to_delhi.top_20")
																: "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_delhi.top_50')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 50 :
														{{
															getValue(seo_detail, "current_rank.top_50")
																? getValue(seo_detail, "current_rank.top_50")
																: "-"
														}}/{{
															getValue(seo_detail, "to_delhi.top_50")
																? getValue(seo_detail, "to_delhi.top_50")
																: "-"
														}}</span
													>
												</v-chip>

												<v-chip
													v-if="getValue(seo_detail, 'to_delhi.top_100')"
													label
													outlined
													color="red darken-4 ml-1 mb-1"
												>
													<span class="fw-600">
														Top 100 :
														{{
															getValue(seo_detail, "current_rank.top_100")
																? getValue(seo_detail, "current_rank.top_100")
																: "-"
														}}/{{
															getValue(seo_detail, "to_delhi.top_100")
																? getValue(seo_detail, "to_delhi.top_100")
																: "-"
														}}</span
													>
												</v-chip>
											</div>
										</div>
									</v-col>
								</v-row>
							</v-layout>

							<v-flex md12 class="d-flex" v-if="!seoTab == 'task'">
								<div class="mr-12">
									<ImageTemplate
										style="max-width: 100px; height: 100px; box-shadow: 0px 0px 1px 0px #888"
										src="../../../../public/media/logos/genic_solution.png"
										circle
									></ImageTemplate>
									<!-- 
									<ImageTemplate
										style="max-width: 30px; height: 30px; box-shadow: 0px 0px 2px 0px #888"
										circle
										:src="`media/logos/${item.value}.png`"
										class="mr-2 mt-1"
									></ImageTemplate> -->
								</div>
								<div class="w-25">
									<!-- <div class="mt-2">
										<span class="fw-600">Project Name : </span>
										<span class="fw-600 primary--text font-level-3-bold text-uppercase">
											Business Thrust
										</span>
									</div> -->
									<!-- <em v-else class="text-muted"> no customer name</em> -->
									<div class="mt-2">
										<span class="fw-600">Domain : </span>
										<span class="fw-500 primary--text"> www.bthust.com </span>
										<!-- <em v-else class="text-muted"> no customer name</em> -->
									</div>
									<div class="mt-2">
										<span class="fw-600">Keyword Count : </span>
										<span class="fw-500 font-level-3-bold primary--text"> 215 </span>
										<!-- <em v-else class="text-muted"> no customer name</em> -->
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2 d-flex">
										<span class="fw-600">Country : </span>
										<div class="d-flex justify-center align-center ml-2">
											<div class="vti__flag mr-2 sg" />
											<span class="ml-1"> Google Singapore</span>
										</div>
										<!-- <em v-else class="text-muted"> no customer name</em> -->
									</div>
									<div class="mt-2 d-flex">
										<span class="fw-600">Domain Expiry: </span>
										<span class="fw-500 ml-2"> {{ formatDate("2023-07-29 10:40:34") }} </span>
										<!-- <em v-else class="text-muted"> no customer name</em> -->
									</div>
									<div class="mt-2">
										<span class="fw-600">SSL Expiry: </span>
										<span class="ml-2">{{ formatDate("2023-07-29 10:40:34") }}</span>
										<!-- <em v-else class="text-muted">no start dte</em>  -->
										<!-- <em v-else class="text-muted"> no customer name</em> -->
									</div>
								</div>
								<div class="w-25">
									<div class="mt-2">
										<span class="fw-600">Start Date : </span>
										<span class="ml-2">{{ formatDate("2023-07-29 10:40:34") }}</span>
										<!-- <em v-else class="text-muted">no start dte</em>  -->
										<!-- <em v-else class="text-muted"> no customer name</em> -->
									</div>
									<div class="mt-2">
										<span class="fw-600">Created At : </span>
										<span class="ml-2">{{ formatDate("2023-07-29 10:40:34") }}</span>
										<!-- <em v-else class="text-muted">no start dte</em>  -->
										<!-- <em v-else class="text-muted"> no customer name</em> -->
									</div>
								</div>
							</v-flex>
						</v-layout>
						<v-layout class="pa-2 pb-0">
							<v-tabs
								v-model="seoTab"
								background-color="transparent"
								color="blue"
								class="custom-tab-transparent w-100"
								active-class="blue darken-4 text-white"
								hide-slider
							>
								<!-- Hide Tab when nothing is going to show -->
								<!-- 
									Everyone can have a look but no update permission
									v-if="
										getPermission('overview_about:full-access') ||
										getPermission('overview_about:view') ||
										getPermission('promised_kpi_customer:full-access') ||
										getPermission('promised_kpi_customer:view') ||
										getPermission('promised_kpi_delhi:full-access') ||
										getPermission('promised_kpi_delhi:view') ||
										getPermission('overview_contract:full-access') ||
										getPermission('overview_contract:view') ||
										getPermission('overview_team:full-access') ||
										getPermission('overview_team:view') ||
										getPermission('overview_competitors:full-access') ||
										getPermission('overview_competitors:view') ||
										getPermission('overview_credentials:full-access') ||
										getPermission('overview_credentials:view')
									" -->
								<v-tab href="#overview"><v-icon small left>mdi-information-outline</v-icon>Overview</v-tab>

								<!-- v-if="isMaster == 1 || getPermission('ranking:view')" -->
								<v-tab href="#ranking"><v-icon small left>mdi-google-analytics</v-icon>Ranking</v-tab>

								<v-tab
									v-if="
										(isMaster == 1 || userType == 'seo') && getPermission('backlink:view')
									"
									href="#backlink"
								>
									<v-icon small left>mdi-link</v-icon>
									Backlink
								</v-tab>
								<!-- v-if="isMaster == 1 || getPermission('ranking_report:view')" -->
								<v-tab href="#ranking_reports">
									<v-icon small left>mdi-file-table</v-icon>
									Ranking reports
								</v-tab>

								<v-tab
									v-if="(isMaster == 1 || userType == 'seo') && getPermission('url_mapping:view')"
									href="#url_mapping"
								>
									<v-icon small left>mdi-web</v-icon>
									URL Mapping
								</v-tab>
								<!-- v-if="(isMaster == 1 || userType == 'seo') && getPermission('keyword_analysis:view')" -->
								<v-tab href="#keyword_analysis">
									<v-icon small left>mdi-file-table</v-icon>
									Keyword Analysis
								</v-tab>
								<!-- v-if="(isMaster == 1 || userType == 'seo') && getPermission('keywords_history:view')" -->
								<v-tab href="#keyword_history">
									<v-icon small left>mdi-file-table</v-icon>
									Keyword History
								</v-tab>
								<v-tab v-if="false && (isMaster == 1 || userType == 'seo')" href="#taskview">
									<v-icon small left>mdi-check-circle-outline</v-icon>
									Task
								</v-tab>
								<!-- v-if="(isMaster == 1 || userType == 'seo') && getPermission('analytics:view')" -->
								<v-tab href="#analytics_frame">
									<v-icon small left>mdi-check-circle-outline</v-icon>
									Analytics
								</v-tab>
								<v-tab v-if="isMaster == 1 || userType == 'seo'" href="#seo-activity">
									<v-icon small left>mdi-check-circle-outline</v-icon>
									Activity Log
								</v-tab>
								<!-- <v-tab href="#backlink_library"><v-icon small left>mdi-library</v-icon>Backlink Library</v-tab> -->

								<!-- <v-tab href="#backlink_library"><v-icon small left>mdi-library</v-icon>Backlink Library</v-tab> -->
							</v-tabs>
						</v-layout>
					</div>
					<div class="tab-layout-content project-tb-content px-2">
						<v-tabs-items v-model="seoTab">
							<v-tab-item
								v-if="seoTab == 'overview'"
								value="overview"
								class="d-flex justify-space-between"
							>
								<OverviewSEO
									v-if="
										getValue(seo_detail, 'id') &&
										/* getValue(seo_detail, 'project.id') &&
										getValue(seo_detail, 'project.sales_person') && */
										getValue(Login_User, 'users_type') == 'seo'
									"
									:seo-id="seo_detail.id"
									:project-id="getValue(seo_detail, 'project.id')"
									@refresh="getSingleProject"
									:detail="seo_detail"
									:url-data="getValue(seo_detail, 'customer_urls.0')"
									:sales-person="getValue(seo_detail, 'project.sales_person')"
									:users-type="userType"
								></OverviewSEO>

								<OverviewSalesAccounts
									v-else-if="
										getValue(seo_detail, 'id') &&
										// getValue(seo_detail, 'project.id') &&
										// getValue(seo_detail, 'project.sales_person') &&
										getValue(Login_User, 'users_type') != 'seo'
									"
									:seo-id="seo_detail.id"
									:project-id="getValue(seo_detail, 'project.id')"
									@refresh="getSingleProject"
									:detail="seo_detail"
									:url-data="getValue(seo_detail, 'customer_urls.0')"
									:sales-person="getValue(seo_detail, 'project.sales_person')"
									:users-type="userType"
								></OverviewSalesAccounts>
							</v-tab-item>

							<!-- && getPermission('ranking:view') -->
							<v-tab-item
								v-if="seoTab == 'ranking' && seo_detail.id && seo_detail.project"
								value="ranking"
								class="d-flex justify-space-between"
							>
								<DomainRankingTab
									:seo-id="seo_detail.id"
									:detail="seo_detail"
									:project-id="getValue(seo_detail, 'project.id')"
								/>
							</v-tab-item>

							<v-tab-item
								v-if="
									seoTab == 'backlink' &&
									(isMaster == 1 || userType == 'seo') &&
									getPermission('backlink_library_settings:view')
								"
								value="backlink"
								class="d-flex justify-space-between"
							>
								<DomainBacklinkTab
									v-if="getValue(seo_detail, 'project.id')"
									:seoActivityList="seoActivityList"
									:seoCategoryList="seoCategoryList"
									:project-id="getValue(seo_detail, 'project.id')"
									:file-name="getValue(seo_detail, 'customer_urls.0.website_urls')"
								/>
							</v-tab-item>

							<!-- && getPermission('ranking_report:view') -->
							<v-tab-item
								v-if="seoTab == 'ranking_reports' && seo_detail?.id && seo_detail.project"
								value="ranking_reports"
							>
								<RankingReportsTab
									v-if="hasKey(Login_User, 'users_type')"
									:seo-id="getValue(seo_detail, 'id')"
									:project-id="getValue(seo_detail, 'project.id')"
									:file-name="getValue(seo_detail, 'customer_urls.0.website_urls')"
									:users-type="userType"
								/>
							</v-tab-item>

							<v-tab-item
								v-if="
									seoTab == 'url_mapping' &&
									(isMaster == 1 || userType == 'seo') &&
									getPermission('url_mapping:view')
								"
								value="url_mapping"
							>
								<URLMappingTab
									:project-id="getValue(seo_detail, 'project.id')"
									v-if="projectId"
									:file-name="getValue(seo_detail, 'customer_urls.0.website_urls')"
									:url="getValue(seo_detail, 'url')"
								/>
							</v-tab-item>

							<!-- &&
									getPermission('keyword_analysis:view') && (isMaster == 1 || userType == 'seo')-->
							<v-tab-item v-if="seoTab == 'keyword_analysis'" value="keyword_analysis">
								<KeywordAnalysis
									:project-id="getValue(seo_detail, 'project.id')"
									v-if="projectId"
									:file-name="getValue(seo_detail, 'customer_urls.0.website_urls')"
								/>
							</v-tab-item>

							<v-tab-item v-if="seoTab == 'keyword_history'" value="keyword_history">
								<KeywordHistory
									v-if="getValue(seo_detail, 'project.id')"
									:seo-id="getValue(seo_detail, 'id')"
									:project-id="hasKey(seo_detail, 'project.id') && getValue(seo_detail, 'project.id')"
									:file-name="getValue(seo_detail, 'customer_urls.0.website_urls')"
								/>
							</v-tab-item>

							<v-tab-item
								v-if="seoTab == 'taskview' && (isMaster == 1 || userType == 'seo')"
								value="taskview"
							>
								<div style="background-color: rgb(238 246 254); height: calc(100vh - 250px)">
									<TaskKanban
										:customer-id="getValue(customer, 'id')"
										:project-id="projectId"
										:contract-id="getValue(contract, 'id')"
									/>
								</div>
							</v-tab-item>

							<!-- &&
									(isMaster == 1 || userType == 'seo') &&
									getPermission('analytics:view') -->
							<v-tab-item
								v-if="seoTab == 'analytics_frame' && hasKey(seo_detail, 'project')"
								value="analytics_frame"
							>
								<Analytics
									:seo-id="getValue(seo_detail, 'id')"
									:project-id="getValue(seo_detail, 'project.id')"
									@refresh="getSingleProject"
								></Analytics>
							</v-tab-item>

							<v-tab-item
								v-if="seoTab == 'seo-activity' && (isMaster == 1 || userType == 'seo')"
								value="seo-activity"
							>
								<!-- <SeoActivityLog
									v-if="hasKey(this.seo_detail, 'id')"
									:related-id="getValue(this.seo_detail, 'id')"
								/> -->
								<ActivityTimelineView
									v-if="hasKey(this.seo_detail, 'id')"
									:related-id="getValue(this.seo_detail, 'id')"
									module="Seo Project"
									:loaded="Loaded.activity"
									@loaded="Loaded.activity = $event"
									:inlineStyle="{ height: 'calc(100vh - 244px)' }"
								></ActivityTimelineView>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</v-layout>
			<v-file-input ref="uploadFile" class="d-none" @change="uploadFile($event)"></v-file-input>
		</div>

		<!-- Status Update of Project -->
		<Dialog :dialog="dialog" :dialog-width="600" @close="closeModal">
			<template #title> Update Status </template>
			<template #body>
				<div class="form-grp-title">
					<div>Status {{ dialog_type }}</div>
					<small>
						<v-icon size="16">mdi-progress-question</v-icon> {{ subTitleStatusChange[dialog_type] }}
						<!-- Tell us a reason for {{ dialog_type }} -->
					</small>
				</div>
				<v-form v-model.trim="formValid">
					<v-row>
						<v-col md="3">
							<label class="required">Reason</label>
						</v-col>
						<v-col md="9">
							<TextAreaInput
								hide-details
								dense
								style="margin-top: 0px !important"
								:disabled="pageLoading"
								v-model="remark"
								:loading="pageLoading"
								:placeholder="subTitleStatusChange[dialog_type]"
								:word-limit="200"
								:rules="[vrules.required(remark, 'Reason')]"
								:customClass="{
									required: !remark,
								}"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template #action>
				<v-btn
					@click="UpdateStatus(dialog_type)"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading || !formValid"
				>
					UPDATE
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog>

		<!-- <Dialog :dialog="statusClose" :dialog-width="600" @close="closeModal">
			<template v-slot:title> Update Status </template>
			<template v-slot:body>
				<div class="form-grp-title">
					<div>Status Close</div>
					<small> <v-icon size="16">mdi-progress-question</v-icon> Tell us a reson for Close </small>
				</div>
				<v-form v-model.trim="formValid">
					<v-row>
						<v-col md="3">
							<label class="">Reason</label>
						</v-col>
						<v-col md="9">
							<TextAreaInput
								hide-details
								dense
								style="margin-top: 0px !important"
								:disabled="pageLoading"
								v-model="remark"
								:loading="pageLoading"
								:placeholder="`Reason for Close`"
								:word-limit="200"
								:rules="[vrules.required(remark, 'Reason')]"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="updateStatus(3)"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading || !formValid"
				>
					UPDATE
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog> -->

		<!-- <Dialog :dialog="rejectDialog" :dialog-width="600" @close="closeModal">
			<template v-slot:title> Reject </template>
			<template v-slot:body>
				<div class="form-grp-title">
					<small> <v-icon size="16">mdi-progress-question</v-icon> Tell us a reson for Reject </small>
				</div>
				<v-form v-model.trim="approve_reject_form_valid">
					<v-row>
						<v-col md="3">
							<label class="">Reason</label>
						</v-col>
						<v-col md="9">
							<TextAreaInput
								hide-details
								dense
								style="margin-top: 0px !important"
								:disabled="pageLoading"
								v-model="statusRemark"
								:loading="pageLoading"
								placeholder="Reject reason"
								:word-limit="200"
								:rules="[vrules.required(statusRemark, 'Reason')]"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="approve_reject(2)"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading || !approve_reject_form_valid"
				>
					UPDATE
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog>

		<Dialog :dialog="approveDialog" :dialog-width="600" @close="closeModal">
			<template v-slot:title> Approve </template>
			<template v-slot:body>
				<div class="form-grp-title">
					<small> <v-icon size="16">mdi-progress-question</v-icon> Tell us a reson for Approval </small>
				</div>
				<v-form v-model.trim="approve_reject_form_valid">
					<v-row>
						<v-col md="3">
							<label class="required">Reason</label>
						</v-col>
						<v-col md="9">
							<TextAreaInput
								hide-details
								dense
								style="margin-top: 0px !important"
								:disabled="pageLoading"
								v-model="statusRemark"
								:loading="pageLoading"
								placeholder="Approve reason"
								:word-limit="200"
								:rules="[vrules.required(statusRemark, 'Reason')]"
							></TextAreaInput>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-on:click="approve_reject(1)"
					color="blue darken-4"
					class="white--text"
					depressed
					tile
					:disabled="pageLoading || !approve_reject_form_valid"
				>
					UPDATE
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="pageLoading"
					color="red"
					class="white--text"
					@click="closeModal"
				>
					No, Close
				</v-btn>
			</template>
		</Dialog> -->

		<!-- sidebar drawers -->

		<!-- <ActivityLog
			:drawer="open_dialog.activity_log"
			v-on:close="open_dialog.activity_log = false"
		></ActivityLog> -->
	</v-sheet>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { toSafeInteger, cloneDeep } from "lodash";
import { GET, POST } from "@/core/services/store/request.module";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import { SET_MESSAGE /* , SET_ERROR */ } from "@/core/services/store/common.module";
/* import SeoActivityLog from "@/view/module/seo/SeoActivityLog"; */
import objectPath from "object-path";
import MainMixin from "@/core/mixins/main.mixin.js";
import { getCurrentUser } from "@/core/services/jwt.service";

// import { SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "Leads-details",
	mixins: [MainMixin],
	data() {
		return {
			pageLoading: false,
			search_open: false,
			formValid: true,
			approve_reject_form_valid: true,
			project: {},
			open_detail: true,
			seo_detail: {},
			credential: false,
			cancelDialog: false,
			customer: {},
			contract: {},
			maintenance: [],
			projectId: 0,
			file_list: [],
			seoActivityList: [],
			seoCategoryList: [],
			statusList: [
				{ title: "Active", value: 1, icon: "mdi-check-circle-outline", color: "green" },
				{ title: "Hold", value: 2, icon: "mdi-cancel", color: "orange" },
				{ title: "Close", value: 3, icon: "mdi-close-circle-outline", color: "red" },
			],
			subTitleStatusChange: {
				Active: "Reason for Activation",
				Hold: "Reason to Hold",
				Close: "Reason to Close",
				Approve: "Reason for Approval",
				Reject: "Reason for Rejection",
			},
			remark: null,
			statusRemark: null,
			status: null,

			statusHold: false,
			statusClose: false,
			rejectDialog: false,
			approveDialog: false,

			dialog: false,
			dialog_type: null,

			seoTeams: {
				sales_owner: {},
				team_lead: {},
			},
			approval_count: 0,
			salesOwner: null,
			Login_User: null,
			Loaded: {
				activity: false,
			},
		};
	},
	components: {
		Dialog: () => import("@/view/components/Dialog"),
		TextAreaInput: () => import("@/view/components/TextAreaInput"),
		OverviewSEO: () => import("@/view/module/seo/Tabs/Overview-SEO.vue"),
		OverviewSalesAccounts: () => import("@/view/module/seo/Tabs/Overview-Sales&Accounts.vue"),
		DomainRankingTab: () => import("@/view/module/domain/DomainRankingTab"),
		DomainBacklinkTab: () => import("@/view/module/domain/DomainBacklinkTab"),
		RankingReportsTab: () => import("@/view/module/domain/RankingReportsTab"),
		URLMappingTab: () => import("@/view/module/domain/URLMappingTab"),
		KeywordAnalysis: () => import("@/view/module/domain/KeywordAnalysis"),
		KeywordHistory: () => import("@/view/module/domain/KeywordHistory"),
		Analytics: () => import("@/view/module/seo/Analytics/Analytics"),
		TaskKanban: () => import("@/view/module/seo/TaskKanban"),
		FilterTask: () => import("@/view/components/FilterTask"),
		ImageTemplate: () => import("@/view/components/Image"),
		ActivityTimelineView: () => import("@/view/components/ActivityTimelineView.vue"),

		// ActivityTab,
		// CancelProject,
		// SeoAnalysis,
		// SeoReport,
		// Files,
		// ProjectDomain,
		// ProjectSLL,
		// ProjectMaintenace,
		// IntenalInvoiceTab,
		// ProjectWeb,
		// ActivityLog,
		/* SeoActivityLog, */
	},
	methods: {
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		backToListing() {
			/* Return back to the listing page */
			let query = this.$route.query;
			this.$router.push({
				name: "seo",
				query: { ...query, tab: null, t: new Date().getTime() },
			});
		},
		uploadFile(data) {
			const file_size = (data.size / 1024).toFixed(1) + "kb";
			const data_opj = { icon: "mdi-file-check-outline", name: data.name, size: file_size };
			this.file_list.push(data_opj);
		},
		closeModal() {
			this.remark = null;
			this.dialog = false;
			this.status = this.seo_detail.status;
		},
		UpdateStatus(dialog_type) {
			switch (dialog_type) {
				case "Active":
					this.updateStatus(1);
					break;
				case "Hold":
					this.updateStatus(2);
					break;
				case "Close":
					this.updateStatus(3);
					break;
				case "Approve":
					this.approve_reject(1);
					break;
				case "Reject":
					this.approve_reject(2);
					break;
			}
		},
		doAction(status) {
			this.dialog = /* status == 1 ? false : */ true;
			switch (status) {
				case 1:
					// this.updateStatus(1);
					this.dialog_type = "Active";
					break;
				case 2:
					this.dialog_type = "Hold";
					break;
				case 3:
					this.dialog_type = "Close";
					break;
				case 4:
					this.dialog_type = "Approve";
					break;
				case 5:
					this.dialog_type = "Reject";
			}
		},
		getBacklinkActivity() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "list-activity" })
				.then((data) => {
					if (data.data && Array.isArray(data.data)) {
						_this.seoActivityList = data.data.map((row) => {
							return { title: row.activity, value: row.value, id: row.id };
						});
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getBacklinkCategory() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "list-category" })
				.then((data) => {
					_this.seoCategoryList = data.data.map((row) => {
						return { title: row.category, value: row.value, id: row.id };
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getSingleProject() {
			let seoId = toSafeInteger(this.$route.params.id);
			this.$store
				.dispatch(GET, {
					url: `seo/${seoId}`,
				})
				.then((data) => {
					this.seo_detail = { ...data };
					this.status = data.status;
					this.projectId = data.project.id;
					this.getSEOTeams(data.project.id);
					this.approval_count = data.account_approve + data.sales_owner_approve + data.team_lead_approve;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		updateStatus(status) {
			this.pageLoading = true;

			let seoId = toSafeInteger(this.$route.params.id);
			let payload = {
				status,
				remark: this.remark,
			};

			const _this = this;
			_this.$store
				.dispatch(POST, { url: `seo-project/status/${seoId}`, data: payload })
				.then(() => {
					if (status == 1) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Status updated successfully | Activated",
							},
						]);
					}
					if (status == 2) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Status updated successfully | Hold",
							},
						]);
					}
					if (status == 3) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Status updated successfully | Closed",
							},
						]);
					}

					this.getSingleProject();
					this.closeModal();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getSEOTeams(projectId) {
			let seoId = toSafeInteger(this.$route.params.id);
			ApiService.get(`seo-team?project_id=${projectId}&seo_id=${seoId}`).then(({ data }) => {
				this.seoTeams = data ? data : this.seoTeams;
			});
		},
		approve_reject(status_action) {
			let seoId = toSafeInteger(this.$route.params.id);
			this.pageLoading = true;
			let payload = {
				status_action,
				reject_remark: this.remark,
			};
			const _this = this;
			_this.$store
				.dispatch(POST, { url: `seo-project/status-approved/${seoId}`, data: payload })
				.then(({ data: { message } }) => {
					/* response = { data: { message } } */
					this.closeModal();
					this.getSingleProject();
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message,
						},
					]);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		pending_approval() {
			let pending = [];
			if (this.seo_detail?.sales_owner_approve == 0) {
				pending.push("Sales Owner");
			}
			if (this.seo_detail?.team_lead_approve == 0) {
				pending.push("Team Lead");
			}
			if (this.seo_detail?.account_approve == 0) {
				pending.push("Account");
			}
			return pending;
		},
		// approval_status() {
		// 	let status = null;
		// 	if (this.seo_detail?.request_status) {
		// 		if (
		// 			this.seo_detail?.sales_owner_approve == 2 ||
		// 			this.seo_detail?.team_lead_approve == 2 ||
		// 			this.seo_detail?.account_approve == 2
		// 		) {
		// 			status = "reject";
		// 		} else if (
		// 			this.seo_detail?.sales_owner_approve == 1 &&
		// 			this.seo_detail?.team_lead_approve == 1 &&
		// 			this.seo_detail?.account_approve == 1
		// 		) {
		// 			status = "approved";
		// 		}
		// 	}
		// 	return status;
		// },
	},

	computed: {
		...mapGetters(["currentUser", "getProfile", "userType", "isMaster"]),
		seoTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.seoTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "overview";
			},
		},
		currentStatus() {
			const status = this.statusList.find((st) => st.value == this.status);
			return status;
		},
		statusToList() {
			const _StatusList = cloneDeep(this.statusList);
			const currStatusIndex = _StatusList.findIndex((st) => st.value == this.status);
			_StatusList.splice(currStatusIndex, 1);
			return _StatusList;
		},
	},
	mounted() {
		this.getSingleProject();
		this.getBacklinkActivity();
		this.getBacklinkCategory();

		this.Login_User = getCurrentUser();

		const { id } = this.$route.params;

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Seo", disabled: false, href: "/seo" },
			{ text: "Detail", disabled: true, href: `/seo-detail/${id}` },
		]);
		// this.salesOwner = this.$route.params.sales
		// HostingEventBus.$on("refresh", () => {
		// 	this.getSingleProject();
		// });
	},
};
</script>
